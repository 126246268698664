<template>

  <!-- {{user}} -->
  <div v-if="notificationData.length" class="col-lg-7 col-md-7">
    <iq-card v-for="(item, index) in notificationData" :key="index" v-infinite-scroll="getRows" :infinite-scroll-disabled="commomData.noResult">
      <template v-slot:body>
        <ul class="notification-list m-0 p-0">
          <li class="d-flex align-items-center justify-content-between">
            <div class="user-img img-fluid">
              <img :src="item.avatar" alt="story-img" class="rounded-circle avatar-40 cursor_pointer" @click="$router.push({path: `${item.user_account}`})" />
            </div>
            <div class="w-100" :style="[item.notify_type === 3 || item.notify_type === 4 ? 'cursor: pointer' : '']" @click="openPost(item)">
              <div class="d-flex justify-content-between">
                <div class="ms-3">
                  <div class="">
                    <h5 class="mb-0 d-inline-block account_info" @click.stop="$router.push({path: `${item.user_account}`})">{{ item.user_name }}</h5>
                    <span class="ms-1 mb-0 d-inline-block">{{ item.user_account }}</span>
                  </div>
                  <h6>{{ item.content }}</h6>
                  <p class="mb-0">{{formatDate(`${item.created_date} ${item.created_time}`)}}</p>
                </div>
                <div class="d-flex align-items-center">
                  <a href="javascript:void(0);" :class="icon[Number(item.notify_type)]">
                    <i :class="color[Number(item.notify_type)]"></i>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </iq-card>
  </div>
  <div v-else class="col-lg-7 col-md-7">
    <div class="card card-block card-stretch card-height">
      <div class="card-body text-center">{{ $t('目前沒有任何資料') }}</div>
    </div>
  </div>
  <SocialRecommendedCreators></SocialRecommendedCreators>
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import { getNotify } from '@/api/notify'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { useDate } from '@/utils/useDate'

export default {
  name: 'Notification',
  setup() {
    const { formatDate } = useDate()
    return { formatDate }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  mounted() {
    socialvue.index()
  },
  created() {
    this.getRows()
  },
  data() {
    return {
      message: '',
      commomData: commomData(),
      primaryKey: 'created_at',
      seen: true,
      // 訂閱 購買 評論 喜歡
      icon: [
        '',
        'me-3 iq-notify bg-soft-warning rounded',
        'me-3 iq-notify bg-soft-primary  rounded',
        'me-3 iq-notify bg-soft-success rounded',
        'me-3 iq-notify bg-soft-danger rounded'
      ],
      color: [
        '',
        'ri-award-line',
        'ri-money-dollar-box-line',
        'ri-chat-4-line',
        'ri-heart-line'
      ],
      notificationData: [
      ],
      action: [
        {
          icon: 'ri-eye-fill me-2',
          title: 'View'
        },
        {
          icon: 'ri-delete-bin-6-fill me-2',
          title: 'Delete'
        },
        {
          icon: 'ri-pencil-fill me-2',
          title: 'Edit'
        },
        {
          icon: 'ri-printer-fill me-2',
          title: 'Print'
        },
        {
          icon: 'ri-file-download-fill me-2',
          title: 'Download'
        }
      ]
    }
  },
  methods: {
    async getRows() {
      if (this.commomData.noResult || this.isFetching) {
        return false
      }
      this.isFetching = true // 鎖定請求
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      await getNotify(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.notificationData = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.notificationData.length) {
            this.notificationData.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.notificationData.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          }
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            title: err.response.data.message
          })
        })
        .finally(() => {
          this.isFetching = false // 請求完成後解鎖
        })
    },
    openPost(item) {
      if (item.notify_type === 3 || item.notify_type === 4) {
        this.$router.push(`/post/${item.content_id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-soft-danger {
  &:hover,
  &:focus {
    color: #e68c7c;
    background-color: rgba(255, 155, 138, 0.2) !important;
  }
}
.bg-soft-warning {
  &:hover,
  &:focus {
    color: #e6a75e;
    background-color: rgba(255, 186, 104, 0.2) !important;
  }
}
.bg-soft-primary  {
  &:hover,
  &:focus {
    color: #48a3e6;
    background-color: rgba(80, 181, 255, 0.2) !important;
  }
}
.bg-soft-success {
  &:hover,
  &:focus {
    color: #29b9a0;
    background-color: rgba(45, 205, 178, 0.2) !important;
  }
}
.iq-notify {
  cursor: default !important;
}
</style>
